<template>
  <v-row justify="center">
    <v-dialog
      v-model="showBranch"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-app-bar
        outlined
        short
        color="#ffffff"
        elevation="0.5"
      > 
        <v-spacer></v-spacer>
        <v-toolbar-title align-self="center" class="text-button font-weight-6 p-0 m-0 ml-2">
          Outlets
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon class="ml-2" @click="close()">mdi-close</v-icon>

        <template v-slot:extension>
          <v-text-field
            v-model.trim="keyword"
            color="grey lighten-1"
            class="search-branch-keyword text-caption mb-4"
            placeholder="Search"
            rounded
            dense
            filled
            hide-details
            autocomplete="off"
          ></v-text-field>
        </template>
      </v-app-bar>
      <v-sheet height="calc(100vh - 104px)" color="#f3f3f3" class="overflow-y-auto p-0">
        <v-card
          flat
          v-for="branch in branches"
          :key="branch.merchant_id"
          class="m-3 p-0"
        >
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img
                  :lazy-src="getMerchantLogo(branch)"
                  height="32"
                  width="32"
                  :src="getMerchantLogo(branch)"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-chip small class="text-caption mr-1 limited-offer" v-if="branch.city">{{ branch.city }}</v-chip>
                  <v-chip small class="text-caption mr-1 limited-offer" v-if="branch.state">{{ branch.state }}</v-chip>
                </v-list-item-subtitle>
                <v-list-item-title
                  style="font-size: 12px !important"
                  class="text-button font-weight-bold mt-2"
                  >{{ branch.restaurant_name }}</v-list-item-title
                >
                <v-list-item-subtitle class="text-caption mt-2" style="font-size: 11px !important">
                  <v-icon x-small color="anchor" class="mr-2">mdi-map-marker</v-icon>{{ branch.street }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon x-small color="anchor" class="mr-2">mdi-phone-forward</v-icon>
                  <a :href="`tel:${branch.contact_phone}`">
                    <small class="light-blue--text text--darken-1" style="font-size: 12px !important">{{ branch.contact_phone }}</small>
                  </a>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text style="cursor: pointer;" class="text-overline font-weight-6" @click.stop="goTo(branch.merchant_id, branch.restaurant_hostname)">
                  Go
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-sheet>
      <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from 'vuex';
import debounce from 'debounce';

export default {
  name: 'BranchModal',
  props: ['visible', 'selected'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      filteredBranches: [],
      keyword: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    branches() {
      return (this.keyword ? this.filteredBranches : this.profile.branch) || [];
    },
    showBranch: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-branch', false);
        }
      },
    },
  },
  methods: {
    getMerchantLogo(branch) {
      if (!branch.logo_s3 && !branch.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return branch.logo_s3 ? (`${this.cdnUrl}/${branch.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${branch.logo}`);
    },
    getMerchantGoogleMap(branch) {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${branch.latitude},${branch.lontitude}&zoom=14&size=200x200&key=${this.googleGeoApiKey}`;
    },
    goTo(merchantId, hostname) {
      this.$gtag.event('select-branch', {
        'event_category': 'navigation',
        'event_label': merchantId,
        'event_value': hostname,
      });
      window.location.href = `https://${hostname}`;
    },
    close() {
      this.showBranch = false;
    }
  },
  mounted() {
    this.filteredBranches = this.profile.branch;
  },
  watch: {
    keyword: debounce(async function (val) {
      if (this.isLoading) return;

      this.isLoading = true;

      if (!val) {
        this.isLoading = false;
        return;
      }

      this.filteredBranches = this.profile.branch.filter(b => b.restaurant_name.toLowerCase().includes(val.toLowerCase()));

      this.isLoading = false;
    }, 700),
  },
};
</script>
<style scoped>
.limited-offer {
  font-size: 8px !important;
  font-weight: 600;
  height: 18px;
  border-radius: 8px;
  color: #FF6347 !important;
  background-color: rgba(255, 0, 0, 0.1) !important;
}
.v-list-item__action-text {
  color: #FF6347 !important;
}
</style>