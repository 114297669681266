<script>
import BottomNav from '@/components/Themes/dayonedayone/BottomNav'
import NotifyUpdateModal from '@/components/DialogModals/NotifyUpdateModal';
import CentralLoader from '@/components/Loaders/CentralLoader';
import DeliveryProviderBottomSheet from '@/components/BottomSheets/DeliveryProviders';
import BranchModal from '@/components/Modals/Branches';
import LandingPopupModal from '@/components/Themes/dayonedayone/Modals/LandingPopup';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import isEqual from 'lodash/isEqual';

export default {
  props: ['installable'],
  components: {
    BottomNav,
    NotifyUpdateModal,
    CentralLoader,
    DeliveryProviderBottomSheet,
    BranchModal,
    LandingPopupModal,
  },
  metaInfo() {
    return {
      title: `${this.profile.restaurant_name}`,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.merchantLogo },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      vapidPublicKey: process.env.VUE_APP_VAPID_PUBLIC_KEY,
      version: null,
      isLoading: false,
      hasUpdate: false,
      title: 'Day One Day One',
      serviceRoutes: {
        menubook: 'menubook',
        delivery: 'menu',
        pickup: 'menu',
        dinein: 'scan',
        reservation: 'reserve',
      },
      showDeliveryProvider: false,
      showBranch: false,
      showPopup: true,
      alertMessage: {
        'invalid-voucher': 'Voucher is invalid or expired!',
      },
    };
  },
  computed: {
    ...mapState('loyalty', {
      loyaltyCustomer: (state) => state.customer,
      pointBalance: (state) => state.pointBalance,
    }),
    ...mapState('media', {
      banners: (state) => state.banners,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
      reviews: (state) => state.reviews,
      storeClosed: (state) => state.storeClosed,
      ondemand: (state) => state.ondemand,
      acceptPreOrder: (state) => state.preOrder,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('client', {
      name: 'getName',
    }),
    ...mapGetters('merchant', {
      rating: ['getRating'],
      isTodayHoliday: ['isTodayHoliday'],
      hasTableBooking: ['hasTableBooking'],
      hasBranch: ['hasBranch'],
    }),
    isIos: function() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    },
    isStandaloneMode: function() {
      return ('standalone' in window.navigator) && (window.navigator.standalone === true) || (window.matchMedia('(display-mode: standalone)').matches);
    },
    appBarBackground() {
      return this.profile.photo_landing_bg ? `${this.cdnUrl}/${this.profile.photo_landing_bg}` : '';
    },
    landingBackground: function() {
      return this.profile.photo_landing_bg ? {
        backgroundImage: `url(${this.cdnUrl}/${this.profile.photo_landing_bg})`
      } : null;
    },
    merchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    hasLinks: function () {
      return Object.values(this.profile?.links || {})?.some(link => !!link);
    },
    viewedLandingPopup() {
      return !!localStorage.olympicLandingPopup;
    },
  },
  methods: {
    ...mapActions('customer', ['claimVoucher']),
    ...mapActions('data', ['createDataAnalyticEvent']),
    ...mapActions('loyalty', ['getLoyaltyPointBalance', 'patchLoyaltyCustomerPWA']),
    ...mapActions('media', ['getBanners']),
    ...mapActions('merchant', ['getMerchantByHostname']),
    ...mapActions('notification', ['subscribe', 'unsubscribe']),
    ...mapMutations('cart', ['SET_SERVICE']),
    hasService(value) {
      return this.profile?.service.find(s => s.value === value);
    },
    navigate(event, routeName) {
      this.$gtag.event(event, {
        'event_category': 'navigation',
        'event_label': 'From',
        'event_value': 'home',
      });

      this.createDataAnalyticEvent({
        event,
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: 'uuid',
          refId: localStorage.uuid,
          category: 'navigation',
          label: 'From',
          value: 'home',
        },
      });

      this.$router.push({ name: routeName }).catch(() => {});
    },
    selectService(label, value) {
      this.$gtag.event('select-service', {
        'event_category': 'navigation',
        'event_label': label,
        'event_value': value,
      });

      if (value !== 'menubook') {
        this.SET_SERVICE(value);
      }

      if (value === 'delivery' && this.hasLinks) {
        this.showDeliveryProvider = true;
      } else {
        this.$router.push({name: this.serviceRoutes[value]}).catch(() => {});
      }
    },
    goTo(merchantId, hostname) {
      this.$gtag.event('select-branch', {
        'event_category': 'navigation',
        'event_label': merchantId,
        'event_value': hostname,
      });

      this.createDataAnalyticEvent({
        event: 'switch-branch',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: 'uuid',
          refId: localStorage.uuid,
          category: 'navigation',
          label: 'To',
          value: `https://${hostname}`,
        },
      });

      window.location.href = `https://${hostname}`;
    },
    scan() {
      this.$gtag.event('scan', {
        'event_category': 'interaction',
        'event_label': 'Scan',
      });
      this.$router.push({name: 'scan'}).catch(() => {});
    },
    install() {
      this.$emit('install', 'home');
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/\\-/g, '+')
        .replace(/_/g, '/');
    
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
    
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    async promptNotification() {
      this.$gtag.event('prompt-notification', {
        'event_category': 'interaction',
        'event_label': 'Prompt Notification',
        'event_value': this.loyaltyCustomer.id,
      });

      this.createDataAnalyticEvent({
        event: 'prompt-notification',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: 'loyalty_clients',
          refId: this.loyaltyCustomer.id,
          category: 'interaction',
        },
      });

      if ('serviceWorker' in navigator && 'PushManager' in window) {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        const currentSubscription = JSON.parse(localStorage.subscription ?? '{}');
        try {
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(this.vapidPublicKey),
          });

          this.$gtag.event('prompt-notification-allowed', {
            'event_category': 'engagement',
            'event_label': 'Prompt Notification (Allowed)',
            'event_value': this.loyaltyCustomer.id,
          });

          this.createDataAnalyticEvent({
            event: 'prompt-notification-allowed',
            data: {
              hqMerchantId: this.profile.hq_merchant_id,
              merchantId: this.profile.merchant_id,
              refTable: 'loyalty_clients',
              refId: this.loyaltyCustomer.id,
              category: 'engagement',
            },
          });

          if (!currentSubscription?.endpoint || !isEqual(currentSubscription, subscription.toJSON())) {
            // Send subscription to your server
            this.subscribe({
              meta: subscription,
            });
          }
        } catch (e) {
          this.$gtag.event('prompt-notification-denied', {
            'event_category': 'engagement',
            'event_label': 'Prompt Notification (Denied)',
            'event_value': this.loyaltyCustomer.id,
          });

          this.createDataAnalyticEvent({
            event: 'prompt-notification-denied',
            data: {
              hqMerchantId: this.profile.hq_merchant_id,
              merchantId: this.profile.merchant_id,
              refTable: 'loyalty_clients',
              refId: this.loyaltyCustomer.id,
              category: 'engagement',
            },
          });

          if (currentSubscription?.endpoint) {
            // Send unsubscription to your server
            this.unsubscribe({});
          }
        }
      } else {
        this.$gtag.event('prompt-notification-unsupported', {
          'event_category': 'experience',
          'event_label': 'Prompt Notification (Unsupported)',
          'event_value': this.loyaltyCustomer.id,
        });

        this.createDataAnalyticEvent({
          event: 'prompt-notification-unsupported',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'loyalty_clients',
            refId: this.loyaltyCustomer.id,
            category: 'experience',
          },
        });
      }
    },
    async autoClaimVouchers() {
      const unclaimedVouchers = JSON.parse(localStorage.vouchers || '[]');
      try {
        this.isLoading = true;

        await Promise.all(unclaimedVouchers.map(v => this.claimVoucher({
          voucherId: v.id,
          merchantId: this.profile.merchant_id,
        })));
      } finally {
        localStorage.removeItem('vouchers');
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.$gtag.pageview(this.$route);

    try {
      this.isLoading = true;

      if (!this.profile) {
        // Get merchant profile
        await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);
      }

      if (this.$route.query?.alert) {
        alert(this.alertMessage[this.$route.query?.alert]);
      }

      if (this.banners?.length === 0) {
        await this.getBanners({
          merchantId: this.profile.merchant_id,
          status: 'publish',
        });
      }

      if (localStorage.token) {
        this.autoClaimVouchers();
      }

      if (this.isLoggedIn) {
        this.getLoyaltyPointBalance({});
      }

      if (this.loyaltyCustomer) {
        if (this.isStandaloneMode && (!this.loyaltyCustomer.pwa || this.loyaltyCustomer.pwaUserAgent !== window.navigator.userAgent)) {
          this.patchLoyaltyCustomerPWA({
            pwa: 1,
          });
        }

        this.promptNotification();
      }
      
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  },
};
</script>
<template>
  <v-card class="home-sec" elevation="0">
    <v-carousel
      cycle
      height="52dvh"
      hide-delimiter-background
      :hide-delimiter="banners.length === 1"
      :show-arrows="false"
    >
      <v-carousel-item
        v-for="(item, i) in banners"
        :key="i"
        :src="`${cdnUrl}/${item.cover}`"
      ></v-carousel-item>
    </v-carousel>
    <CentralLoader v-if="isLoading || !profile" color="white"></CentralLoader>
    <div class="home-body">
      <v-card class="container-join-member" elevation="0" v-if="!loyaltyCustomer">
        <div class="row m-0 p-0">
          <div class="col-8">
            <h5 class="font-weight-bold" v-if="!isLoggedIn">Welcome<br/>DayOne DayOne</h5>
            <h5 class="font-weight-bold mb-0" v-else>Hi<br/>{{ name }},</h5>
            <div class="text-small letter-spacing-0 pt-1" v-if="!isLoggedIn">
              Sign up as member, activate to enjoy VIP privileges
            </div>
            <div class="text-small letter-spacing-0 pt-1" v-else>
              Join membership, activate to enjoy VIP privileges
            </div>
          </div>
          <div class="col-4 p-2 text-right">
            <v-img
              contain
              src="@/assets/dayonedayone/images/signup-btn.webp"
              class="position-absolute"
              height="100px"
              position="center"
              style="z-index:7; top:-35px; left:0px;"
            ></v-img>
            <v-btn
              class="text-capitalize font-weight-bold letter-spacing-0 rounded-lg p-2"
              style="width:90%; top:53px; font-size:10px; background-color: #e1cfc7;"
              depressed
              @click="navigate('signup', 'signup')"
              v-if="!isLoggedIn"
            >
              <span>Sign-up</span> / <span class="tertiary--text">Log In</span>
            </v-btn>
            <v-btn
              class="text-capitalize font-weight-bold letter-spacing-0 rounded-lg p-2"
              style="width:90%; top:53px; font-size:10px; background-color: #e1cfc7;"
              depressed
              @click="navigate('join', 'loyalty-signup')"
              v-else
            >
              <span class="tertiary--text">Join Now</span>
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="container-loyalty-profile" elevation="0" v-else>
        <div class="row m-0 p-0">
          <div class="col-9">
            <h5 class="font-weight-bold mb-0">Hi {{ name }}, <br/><span class="tertiary--text">{{ pointBalance }}</span> <span class="text-body-2 font-weight-6">points</span></h5>
            <div class="text-caption font-weight-5">
              DayOne DayOne Member
            </div>
          </div>
          <div class="col-3 pl-0">
            <v-img
              contain
              src="@/assets/dayonedayone/images/container-home-profile.webp"
              class="position-absolute"
              height="60px"
              position="center"
              style="z-index:7; top:15px; left:0px;"
            ></v-img>
          </div>
        </div>
      </v-card>
      <div class="container-menu mt-4">
        <div class="row m-0 p-0">
          <div class="col-12 p-0">
            <div class="sub-container-menu">
              <div class="row m-0" style="height:9dvh;">
                <div class="col-2 p-2">
                  <v-img
                    contain
                    height="7dvh"
                    position="center right"
                    src="@/assets/dayonedayone/images/sub-menu-store.webp"
                  ></v-img>
                </div>
                <div class="col-6 d-flex flex-column justify-space-between p-2 pl-3">
                  <h6 class="font-weight-bold mb-0" style="font-size:1.0rem;">{{ profile.restaurant_name }}</h6>
                  <div style="font-size:0.5rem;">Click for DayOne DayOne Stores</div>
                </div>
                <div class="col-4 p-2 pt-3 text-right">
                  <v-menu
                    content-class="outlet-list rounded-lg font-weight-6 letter-spacing-0"
                    max-width="372"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="text-capitalize font-weight-6 letter-spacing-0 rounded-lg"
                        color="#e1cfc7"
                        depressed
                        style="width:90%; font-size:10px;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Select Outlet
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        active-class="outlet-list-active"
                        v-for="(branch, index) in profile.branch"
                        :key="index"
                      >
                        <v-list-item-title @click.stop="goTo(branch.merchant_id, branch.restaurant_hostname)">{{ branch.restaurant_name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div class="row align-end m-0 mt-4 pb-3" style="height:12dvh;">
                <div class="col-3 p-2" @click="navigate('view-promotion', 'promotion')">
                  <v-img
                    contain
                    class="position-relative"
                    height="6dvh"
                    position="top center"
                    src="@/assets/dayonedayone/images/signatures.webp"
                    style="top:-5px;"
                  >
                  </v-img>
                  <div class="sub-menu-label">Promotions</div>
                </div>
                <div class="cursor-pointer col-3 p-2" @click="selectService('Menubook', 'menubook')">
                  <v-img
                    contain
                    height="6dvh"
                    position="center center"
                    src="@/assets/dayonedayone/images/emenu.webp"
                  >
                  </v-img>
                  <div class="sub-menu-label">E-Menu</div>
                </div>
                <div class="cursor-pointer col-3 p-2" @click="selectService('Delivery', 'delivery')">
                  <v-img
                    contain
                    height="6dvh"
                    position="center center"
                    src="@/assets/dayonedayone/images/delivery.webp"
                  >
                  </v-img>
                  <div class="sub-menu-label">Delivery</div>
                </div>
                <div class="cursor-pointer col-3 p-2" @click="selectService('Pickup', 'pickup')">
                  <v-img
                    contain
                    height="6dvh"
                    position="bottom center"
                    src="@/assets/dayonedayone/images/pickup.webp"
                  >
                  </v-img>
                  <div class="sub-menu-label">Pick-up</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-divider class="footer-divider m-0 p-0"></v-divider>
    <BottomNav :fixed="true"/>
    <notify-update-modal :version="version" v-if="version && hasUpdate"></notify-update-modal>
    <delivery-provider-bottom-sheet :visible="showDeliveryProvider" v-on:close-delivery-provider="showDeliveryProvider = false"></delivery-provider-bottom-sheet>
    <branch-modal :visible="showBranch" v-on:close-branch="showBranch = false"></branch-modal>
    <landing-popup-modal :visible="showPopup && loyaltyCustomer && !viewedLandingPopup" v-on:close-popup="showPopup = false"></landing-popup-modal>
  </v-card>
</template>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.home-sec {
  height: 100dvh;
  height: -webkit-fill-available;
  padding-bottom: 45px;
  background-color: rgb(247, 239, 232) !important;
}

.home-sec /deep/ .v-carousel__controls__item.v-btn .v-btn__content .v-icon {
  font-size: 12px !important;
}

.home-body {
  min-height: calc(48dvh - 48px - 35px);
  position: relative;
  z-index: 5;
  top: -35px;
  background-color: transparent !important;
}

.container-join-member {
  width: 90%;
  margin: 0 auto;
  background-color: rgba(247, 239, 232, 0.8) !important;
}

.container-loyalty-profile {
  width: 90%;
  margin: 0 auto;
  background-color: rgba(247, 239, 232, 0.8) !important;
}

.container-menu {
  background-image: url('~@/assets/dayonedayone/images/home-menu-bg.png');
  background-size: contain;
  background-position: right;
  background-color: #f7efe8 !important;
}

.sub-container-menu {
  width: 90%;
  height: 22dvh;
  background-color: #fcf7f4 !important;
  margin: 0 auto;
}

.sub-container-menu .sub-menu-label {
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  margin-top: 3px;
}

.home-menu-footer-img {
  width: 90%;
  margin: 16px auto;
  padding-left: 55%;
}

.outlet-list {
  background-color: #f7efe8;
}

.outlet-list .v-list {
  background-color: #f7efe8;
}

.outlet-list .v-list .v-list-item .v-list-item__title {
  font-size: 12px;
  font-weight: bold;
}

.outlet-list-active {
  background-color: rgba(247, 239, 232, 0.8);
}

.footer-divider {
  position: fixed;
  bottom: 48.55px;
  width: 100%;
  background-color: rgb(249, 244, 236);
}

@media screen and (min-width: 320px) {
  .service-option {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 360px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .service-option img, .service-option .v-icon {
    width: 18px;
    height: 18px;
  }
}
@media screen and (min-width: 375px) {
  .service-option {
    padding-bottom: 14px;
    padding-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (height: 667px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 1023px) {
}
@media screen and (min-width: 1024px) {
}
</style>
