import { render, staticRenderFns } from "./DeliveryProviders.vue?vue&type=template&id=06fd48a4&scoped=true&"
import script from "./DeliveryProviders.vue?vue&type=script&lang=js&"
export * from "./DeliveryProviders.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryProviders.vue?vue&type=style&index=0&id=06fd48a4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fd48a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBottomSheet,VCard,VCardTitle,VIcon,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle})
