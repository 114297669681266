<template>
  <v-dialog persistent v-model="show" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-overline font-weight-6 primary--text justify-center">System Update ({{ version.version }})</v-card-title>
      <v-card-text class="pb-3" v-if="version.changelog" v-html="version.changelog"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="font-weight-6"
          small
          text
          plain
          @click="show = false"
        >
          Got it!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: ['version'],
  data() {
    return {
      show: true,
    }
  },
};
</script>

<style scoped>
</style>
