<template>
  <i
    class="fa  fa-circle-o-notch  fa-spin fa-2x position-absolute fa-fw"
    :class="'text-' + color"
    style="top: 50%;left: 45%"
  ></i>
</template>

<script>
export default {
  name: 'CentralLoader',
  props: ['color'],
};
</script>

<style scoped></style>
