<template>
  <v-bottom-sheet v-model="showDeliveryProvider">
    <v-card min-height="20vh" max-height="60vh" class="overflow-y-auto p-0">
      <v-card-title class="text-button font-weight-6 justify-center">
        <v-icon color="primary" class="mr-2">mdi-moped-electric</v-icon>
        Delivery Partners
      </v-card-title>
      <v-card height="8" color="listBackground"></v-card>
      <v-card
        flat
        color="listBackground"
        class="pb-2 px-0"
      >
        <v-list color="#ffffff">
          <v-list-item
            @click="$router.push({name: 'menu'}).catch(() => {})"
          >
						<v-list-item-avatar class="my-0">
              <v-img
                :lazy-src="merchantLogo"
                max-height="24"
                max-width="24"
                :src="merchantLogo"
              ></v-img>
						</v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-6">
                In-House
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-for="(link, key) in profile.links">
            <v-list-item
              v-if="link"
              :key="key"
              @click="selectDeliveryProvider(key, link)"
            >
              <v-list-item-avatar class="my-0">
                <v-img
                  :lazy-src="require(`@/assets/images/${deliveryProviderName[key].icon}`)"
                  max-height="24"
                  max-width="24"
                  :src="require(`@/assets/images/${deliveryProviderName[key].icon}`)"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-6">
                  {{ deliveryProviderName[key].label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'DeliveryProviderBottomSheet',
  props: ['visible'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      deliveryProviderName: {
        grabfood: {
          label: 'GrabFood',
          icon: 'grabfood.png'
        },
        foodpanda: {
          label: 'Foodpanda',
          icon: 'foodpanda.jpeg'
        },
        shopeefood: {
          label: 'ShopeeFood',
          icon: 'shopeefood.jpeg'
        },
        airasiafood: {
          label: 'AirAsia Food',
          icon: 'airasiafood.jpeg'
        },
        easi: {
          label: 'EASI',
          icon: 'easi.jpeg'
        },
        bungkusit: {
          label: 'Bungkusit',
          icon: 'bungkusit.png'
        }
      }
    }
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    merchantLogo: function() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    showDeliveryProvider: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-delivery-provider', false);
        }
      },
    },
  },
  methods: {
    selectDeliveryProvider(key, url) {
      this.$gtag.event('select-delivery-provider', {
        'event_category': 'navigation',
        'event_label': key,
        'event_value': url,
      });
      window.open(url, '_blank');

      this.showDeliveryProvider = false;
    }
  }
};
</script>
<style scoped>
.v-list-item:not(:first-child) {
  border-top: 0.55px solid rgba(100, 100, 100, .1);
}
.v-list-item__title {
  font-size: 14px;
}
</style>