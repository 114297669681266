<template>
  <v-dialog content-class="page-landing-popup" v-model="show" transition="dialog-bottom-transition">
    <v-card height="90dvh" class="px-2">
      <v-card-title class="text-body-1 font-weight-bold tertiary--text justify-start w-75 pt-3 pb-0 px-0 mt-2 mb-0 mx-0">
        <v-img
          class="img-landing-pop-header p-0"
          width="70%"
          contain
          :src="`${cdnUrl}/assets/images/loyalty/${profile.hq_merchant_id}/popup/home-popup-header.svg`"
        ></v-img>
        <v-img
          class="img-landing-pop-header-2 p-0"
          width="37%"
          contain
          :src="`${cdnUrl}/assets/images/loyalty/${profile.hq_merchant_id}/popup/home-popup-header-right.webp`"
        ></v-img>
      </v-card-title>
      <!-- <v-card-subtitle class="text-body-1 font-weight-bold dark--text text-center pb-3">
        Voucher at My Rewards
      </v-card-subtitle> -->
      <v-card-text class="text-caption justify-center py-0">
        <v-img
          class="img-landing-pop"
          contain
          :src="`${cdnUrl}/assets/images/loyalty/${profile.hq_merchant_id}/popup/home-popup-voucher.svg`"
        ></v-img>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#7d539f"
          class="text-caption white--text font-weight-6 px-4"
          @click="viewed()"
        >Okay</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: ['visible'],
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
    };
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapState('loyalty', {
      loyaltyCustomer: (state) => state.customer,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    show: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close', false);
        }
      },
    },
  },
  methods: {
    ...mapActions('data', ['createDataAnalyticEvent']),
    viewed() {
      this.createDataAnalyticEvent({
        event: 'olympic-landing-popup-viewed',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          refId: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          category: 'engagement',
        },
      });

      localStorage.removeItem('landingPopup');
      localStorage.olympicLandingPopup = dayjs().format('YYYY-MM-DD HH:mm:ss');
      this.$router.push({ path: '/loyalty/reward?default=1' });
    },
  }
};
</script>

<style>
.page-landing-popup {
  height: 90dvh;
  border-radius: 20px;
  margin-top: -20px;
}

.page-landing-popup .v-card {
  /* background-color: rgba(247, 239, 232, 0.9) !important; */
  background-color: rgba(209, 195, 221, 0.9) !important;
}

.page-landing-popup .img-landing-pop {
  height: calc(80dvh - 80px - 60px);
}

.img-landing-pop-header-2 {
  position: absolute;
  top: 10px;
  right: 0px;
}
</style>
