<script>
const InstallPWAiOSModal = () => import(/* webpackPrefetch: true */'@/components/Themes/dayonedayone/Modals/InstallPWAiOS');
const NotifyUnsupportedBrowserModal = () => import(/* webpackPrefetch: true */'@/components/DialogModals/NotifyUnsupportedBrowserModal');

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: ['fixed'],
  data() {
    return {
      btnPwaKey: 'btn-pwa-key-0s',
      deferredPrompt: null,
      showInstall: false,
      showUnsupportedBrowser: false,
    }
  },
  components: {
    InstallPWAiOSModal,
    NotifyUnsupportedBrowserModal,
  },
  computed: {
    ...mapState('customer', ['customer']),
    ...mapState('loyalty', {
      loyaltyCustomer: (state) => state.customer,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('cart', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
    }),
    isBrowserSupported: function() {
      return !(/(.browser|.fbav|.micromessenger(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent) && (/(opera|chrome|safari|firefox|msie(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent);
    },
    isIos: function() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    },
    isStandaloneMode: function() {
      return ('standalone' in window.navigator) && (window.navigator.standalone);
    },
  },
  methods: {
    ...mapActions('data', [
      'createDataAnalyticEvent',
    ]),
    navigate(event, routeName) {
      this.$gtag.event(event, {
        'event_category': 'navigation',
        'event_label': 'From',
        'event_value': 'home',
      });

      this.createDataAnalyticEvent({
        event,
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          refId: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          category: 'navigation',
          label: 'From',
          value: 'bottom-nav',
        },
      });

      this.$router.push({ name: routeName }).catch(() => {});
    },
    toHome() {
      if (this.isDinein) {
        if (this.$cookies.get('table-no')) {
          return `/dinein/${this.$cookies.get('table-no')}`;
        } else {
          return `/scan`;
        }
      } else {
        return `/menu`;
      }
    },
    getWhatsAppUrl(contactPhone) {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 'https://api.whatsapp.com/send?phone='
        : 'https://web.whatsapp.com/send?phone=')
        + contactPhone;
    },
    installPWA() {
      this.$gtag.event('install-pwa', {
        'event_category': 'interaction',
        'event_label': 'Install PWA',
        'event_value': 'bottom_nav',
      });

      this.createDataAnalyticEvent({
        event: 'install-pwa',
        data: {
          hqMerchantId: this.profile.hq_merchant_id,
          merchantId: this.profile.merchant_id,
          refTable: 'uuid',
          refId: localStorage.uuid,
          category: 'interaction',
          label: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
          value: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
        },
      });

      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choice) => {
          if (choice.outcome === 'accepted') {
            // Do something additional if the user chose to install
            this.$gtag.event('install-pwa-accepted', {
              'event_category': 'engagement',
              'event_label': 'Accepted Install PWA',
              'event_value': 'bottom_nav',
            });

            this.createDataAnalyticEvent({
              event: 'install-pwa-accepted',
              data: {
                hqMerchantId: this.profile.hq_merchant_id,
                merchantId: this.profile.merchant_id,
                refTable: 'uuid',
                refId: localStorage.uuid,
                category: 'engagement',
                label: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
                value: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
              },
            });
          } else {
            // Do something additional if the user declined
            this.$gtag.event('install-pwa-declined', {
              'event_category': 'engagement',
              'event_label': 'Declined Install PWA',
              'event_value': 'bottom_nav',
            });

            this.createDataAnalyticEvent({
              event: 'install-pwa-cancelled',
              data: {
                hqMerchantId: this.profile.hq_merchant_id,
                merchantId: this.profile.merchant_id,
                refTable: 'uuid',
                refId: localStorage.uuid,
                category: 'engagement',
                label: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
                value: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
              },
            });
          }
          this.deferredPrompt = null;
        });
      } else if (this.isIos) {
        this.$gtag.event('install-pwa-ios-guide', {
          'event_category': 'engagement',
          'event_label': 'Install PWA (iOS Guide)',
          'event_value': 'bottom_nav',
        });

        this.createDataAnalyticEvent({
          event: 'install-pwa-ios-guide',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'uuid',
            refId: localStorage.uuid,
            category: 'engagement',
            label: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
            value: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          },
        });

        this.showInstall = true;
      } else if (!this.isIos && !this.isBrowserSupported) {
        this.$gtag.event('unsupported-browser', {
          'event_category': 'experience',
          'event_label': 'Unsupported Browser (PWA)',
          'event_value': 'pwa',
        });

        this.createDataAnalyticEvent({
          event: 'install-pwa-browser-unsupported',
          data: {
            hqMerchantId: this.profile.hq_merchant_id,
            merchantId: this.profile.merchant_id,
            refTable: 'uuid',
            refId: localStorage.uuid,
            category: 'experience',
            label: this.loyaltyCustomer ? 'loyalty_clients' : (this.customer ? 'client' : 'uuid'),
            value: this.loyaltyCustomer?.id ?? this.customer?.phoneNo ?? localStorage.uuid,
          },
        });

        this.showUnsupportedBrowser = true;
      }
    },
  },
  async beforeCreate() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      this.deferredPrompt = e;
    });

    setTimeout(() => {
      this.btnPwaKey = 'btn-pwa-key-30s';
    }, 30000);
  },
};
</script>
<template>
  <div>
    <v-bottom-navigation
      background-color="#e1cfc7"
      class="bottom-nav-home"
      :fixed="fixed"
      height="48px"
      hide-on-scroll
    >
      <v-btn active-class="" class="text-decoration-none h-auto" to="/">
        <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Home</span>
      </v-btn>

      <v-btn active-class="" class="text-decoration-none h-auto" @click.stop="navigate('view-rewards', 'loyalty-reward-tabs')">
        <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Loyalty</span>
      </v-btn>

      <v-btn active-class="" class="text-decoration-none h-auto" @click.stop="installPWA()" :key="btnPwaKey">
        <v-img
          position="center"
          src="@/assets/dayonedayone/images/install-icon.webp"
          style="top:-35px"
          width="60px"
        ></v-img>
        <span
          class="position-relative text-caption font-weight-bold"
          style="color:#f79520;
          bottom:-42px"
          v-if="(deferredPrompt || isIos) && isBrowserSupported && (!loyaltyCustomer || !loyaltyCustomer.pwa)"
        >Install</span>
        <span
          class="position-relative text-caption font-weight-bold"
          style="color:#f79520;
          bottom:-42px"
          v-else
        >小时光</span>
      </v-btn>

      <v-btn active-class="" class="text-decoration-none h-auto" :href="getWhatsAppUrl(profile.contact_phone)" target="_blank">
        <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Chat</span>
      </v-btn>

      <v-btn active-class="" class="text-decoration-none h-auto" to="/profile">
        <span class="bottom-nav-text text-caption black--text font-weight-bold my-1">Profile</span>
      </v-btn>
    </v-bottom-navigation>
    <InstallPWAiOSModal :visible="showInstall" @close="showInstall = false"></InstallPWAiOSModal>
    <notify-unsupported-browser-modal :visible="showUnsupportedBrowser" source="pwa" v-on:close="showUnsupportedBrowser = false"></notify-unsupported-browser-modal>
  </div>
</template>

<style scoped>
.bottom-nav-home {
  box-shadow: none;
  padding: 0 5%;
  z-index: 9999;
}

.v-btn /deep/ i.rounded-circle {
  background-color: var(--v-primary) !important;
}

.v-bottom-navigation .v-btn {
  min-width: 70px !important;
}

@media screen and (min-width: 1024px) {
  .bottom-nav-home {
    padding: 0 20px !important;
  }
}
</style>
